<template>
  <div class="search">
    <div v-if="!selected && !loadingSingle">
      <b-form-group :invalid-feedback="validationInvalidFeedback(errorFeed, errorKey)" class="mb-0">
        <b-form-input :state="validationState(errorFeed, errorKey)" class="mb-0" :disabled="disabled" :placeholder="`Search for ${this.moduleDisplay}...`" tabindex="0" :id="`search-field-${this._uid}`" @focus="searchFieldFocus" @update="search" v-model="term"></b-form-input>
      </b-form-group>
      <b-popover class="p-0 w-100" placement="bottom" :target="`search-field-${this._uid}`" triggers="focus" id="search-results">
        <div class="text-center">
          <p class="my-3 text-muted" v-if="!loading && !results.length">Please start typing to search for {{ moduleDisplay }}.</p>
          <b-spinner class="mx-4 my-2" v-if="loading || !results"></b-spinner>
        </div>
        <b-list-group v-if="!loading && results">
          <b-list-group-item @click.prevent="select(result.id)" href="#" :key="result.id" v-for="result in results">
            <slot name="popover-list" :result="result" />
          </b-list-group-item>
        </b-list-group>
      </b-popover>
    </div>
    <b-card v-if="selected || loadingSingle" :no-body="isSlim">
      <b-row :class="{ 'w-100 mx-0': isSlim }">
				<b-col cols="12" :class="{ 'p-0': isSlim }">
					<div class="d-flex flex-row" :class="{ 'p-2': !isSlim }">
            <b-spinner :class="{ 'm-2': !isSlim }" v-if="loadingSingle" />
						<div class="d-flex flex-fill" :class="{ 'slim-title': isSlim }">
							<b-row class="w-100" :class="{ 'ml-md-1': !isSlim,  'mr-md-1': !isSlim }">
								<b-col>
									<slot name="selected" :selected="selected" v-if="!loadingSingle" />
								</b-col>
								<b-col v-if="$slots['selected-inline-form-area']">
									<slot name="selected-inline-form-area" />
								</b-col>
							</b-row>
​
							<small class="text-danger">{{ validationInvalidFeedback(errorFeed, errorKey) }}</small>
						</div>
						<b-button @click.prevent="deselect" variant="primary" :class="{ 'btn-slim': isSlim }" v-if="!disabled"><fa-icon icon="pen" /></b-button>
						<b-button class="ml-2" @click.prevent="$emit('delete', null)" variant="danger" v-if="!disabled && showDelete"><fa-icon icon="trash" /></b-button>
					</div>
				</b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <slot name="selected-form-area" />
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import validation from '../mixins/validation'

export default {
  mixins: [validation],
  async mounted() {
    if(this.value) {
      if (typeof this.value == 'object' && this.value.id != null) {
        this.loadingSingle = true
        var fetchSingleParam = this.value.id
        if(this.fetchParams.hasOwnProperty('order_item_id')) {
          var fetchSingleParam = { id: this.value.id, extraParams: this.fetchParams }
        }

        await this.fetchSingle(fetchSingleParam).then(data => {
          this.loadingSingle = false;
          this.selected = data.data;
          this.$emit('input', this.selected);
        });
      }else{
        if(this.value > 0 && this.value != null) {
          this.loadingSingle = true;
          var fetchSingleParam = this.value
          if(this.fetchParams.hasOwnProperty('orderItem')) {
            var fetchSingleParam = { id: this.value, extraParams: this.fetchParams }
          }

          await this.fetchSingle(this.value).then(data => {
            this.loadingSingle = false;
            this.selected = data.data;
            this.$emit('input', this.selected);
          });
        }
      }
    }
  }, 
  props: {
    disabled: Boolean,
    fetchParams: {
      type: Object,
      default: () => ({})
    },
    module: String,
    showDelete: {
      type: Boolean,
      default: false,
    },
    isSlim: {
      type: Boolean,
      default: false,
    },
    waitForInput: {
      type: Boolean,
      default: true,
    },
    errorFeed: Object,
    errorKey: String,
    inputState: {
      type: Boolean,
      default: null,
    },
    value: Object,
  },
  data() {
    return {
      loadingSingle: false,
      selected: null,
      term: '',
    };
  },
  computed: {
    ...mapState({
      results(state) {
        return state[this.module].data;
      },
      loading(state) {
        return state[this.module].loading;
      }
    }),

    moduleDisplay() {
      return this.module.replace('-', ' ');
    }
  },
  methods: {
    ...mapActions({
      fetch(dispatch, payload) {
        return dispatch(`${this.module}/fetchMany`, payload);
      },
      fetchSingle(dispatch, payload) {
        return dispatch(`${this.module}/fetch`, payload);
      },
      empty(dispatch, payload) {
        return dispatch(`${this.module}/empty`);
      },
    }),
    searchFieldFocus() {
      if(!this.waitForInput) this.search();
    },
    select(id) {
      this.selected = this.results.find(x => x.id === id);
      this.$emit('input', this.selected);
      this.empty();
    },
    deselect() {
      if(this.returnPrevious) {
        this.$emit('input', { old: this.selected, new: null });
      }else{
        this.$emit('input', { id: null });
      }
      this.selected = null;
      this.empty();
    },
    search() {
      return this.fetch({ query: this.term, 'page': 1, extraParams: this.fetchParams })
    },
  },
}
</script>

<style lang="scss">
.slim-title {
  max-width: calc(100% - 2.5rem);
}
.btn-slim {
  border-radius: 0px 0.25rem 0.25rem 0px !important;
  border: unset;
}

#search-results {
  max-width: 50%;
  width: 50%;
  max-height: 50vh;
  overflow-y: scroll;

  .popover-body {
    padding: 0 !important;
    border: none;
  }
  .list-group-item {
    border-left: none;
    border-right: none;
  }
  .list-group-item:first-child {
    border-top: none;
  }
  .list-group-item:last-child {
    border-bottom: none;
  }
}
</style>
